export default {
  totalAmountMarginTop: '3rem',
  loanCalculatorBorderWidth: '1px',
  loanCalculatorValueColor: 'var(--black)',
  summaryButtonContent: '✓ ',
  summaryButtonSelectedBackground: 'var(--white)',
  summaryButtonSelectedColor: 'var(--teal)',
  userAgreementMarginTop: '4rem',
  prefillBackroundColor: '#FFF2E8',
  prefilllColor: '#000000',
  validatedBrandColor: '#FF7000"',
};
