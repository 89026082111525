import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { change, stopSubmit } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import styled, { css } from 'styled-components';

import intlShape from '../../schemas/intl';
import ValidatedForm from '../../../components/molecules/ValidatedForm';
import MonthlyCost from '../../../components/molecules/MonthlyCost/MonthlyCost';
import YearlyCost from '../../../components/molecules/YearlyCost/YearlyCost';
import { getCookie } from '../../utils/cookies';
import { ButtonGroup } from '../../../components/atoms/ButtonGroup/ButtonGroup';
import { setVisibleField } from '../../store/actions/api/set-generic-value';

import removeFalseyInObject from '../../utils/remove-falsey-in-object';
import onSubmitFail from '../../utils/on-submit-fail';
import messages from '../../utils/messages';
import stripDelimiterFromCurrency from '../../utils/strip-delimiter-from-currency';
import { region, brand } from '../../localizations/current-locale';

import '../../utils/redirect';
import FormTypes from '../../../libs/constants/form-types';
import defaultBreakpoints from '../../../libs/constants/SE/breakpoints';
import ApplicationFormBase from '../ApplicationFormBase/ApplicationFormBase';
import validateApplicationForm from '../../validators/SE/applicationForm';

import InterestRateInput from '../fields/InterestRateInput';
import TotalAmountInput from '../fields/TotalAmountInput';
import RepaymentTimeInput from '../fields/RepaymentTimeInput';
import EmploymentSinceInputYear from '../fields/EmploymentSinceInputYear';
import EmploymentSinceInputMonth from '../fields/EmploymentSinceInputMonth';
import ConsolidationLoanAmountInput from '../fields/ConsolidationLoanAmountInput';
import EmployerName from '../fields/EmployerName';
import EmployerPhone from '../fields/EmployerPhone';

import TestCurrentLoanSummary from '../GenericComponents/TestCurrentLoanSummary/TestCurrentLoanSummary';
import TotalLoanWarning from '../GenericComponents/TotalLoanWarning/TotalLoanWarning';
import ExpandingGridList from '../GenericComponents/ExpandingGridList/ExpandingGridList';
import Teleporter from '../GenericComponents/Teleporter/Teleporter';
import SubmitButton from '../GenericComponents/SubmitButton/SubmitButton';
import AcceptNewsLetter from '../GenericComponents/AcceptNewsLetter/AcceptNewsLetter';
import AcceptAgreement from '../GenericComponents/AcceptAgreement/AcceptAgreement';
import CreditCheckConsent from '../GenericComponents/CreditCheckConsent/CreditCheckConsent';
import Break from '../GenericComponents/Break/Break';
import FormTextBlock from '../GenericComponents/FormTextBlock/FormTextBlock';
import FormBottom from '../GenericComponents/FormBottom';
import SummarySection from '../fields/LoanCalculatorSummary';
import ReturningCustomerSlide from '../fields/ReturningCustomerSlide';

import LanguageContext from '../../../context/language';
import style from './style';
import FormHeadline from '../GenericComponents/FormHeadline';
import {
  formData,
  calculator,
  lead,
  mortgageFormData,
} from '../data';
import TotalCost from '../../../components/molecules/TotalCost';
import devices from '../../styles/Devices';
import SmallFormLabel from '../GenericComponents/SmallFormLabel';
import StoreValueDisplay from '../GenericComponents/StoreValueDisplay/StoreValueDisplay';
import SimpleValueLabel from '../GenericComponents/SimpleValueLabel/SimpleValueLabel';
import Disclaimer from '../../../components/utils/Disclaimer/Disclaimer';
import FormQuestionsBox from '../../../components/utils/FormQuestionsBox';
import ProgressBarStepper from '../GenericComponents/ProgressBarStepper/ProgressBarStepper';
import { TotalAmountDisplay } from '../fields/TotalAmountDisplay';
import { RepaymentTimeDisplay } from '../fields/RepaymentTimeDisplay';
import UserAgreementDataPolicy from '../fields/UserAgreementDataPolicy';
import { dynamicBrand } from '../../utils/brand';
import AutoAccept from '../../../components/atoms/AutoAccept';
import IdKollenLogin from '../IdKollen/IdKollenLogin';

let textResource;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  textResource = m.default;
});

const FormWrapper = styled.div`
  && {
    color: ${style.formTextColor};
    padding: ${(props) => (props.padding)};
    padding-top: ${style.formPaddingTopBottom};
    padding-bottom: ${style.formPaddingTopBottom};

    @media ${devices.downFromTablet} {
      padding: ${style.loanCalculatorPaddingMobile};
    }
  }
`;

const FieldGridWrapper = styled.div`
  && {
    display: grid;
    grid-template-columns: ${(props) => (props.gridColumns ? props.gridColumns : style.gridTemplate)};
    grid-column-gap: ${(props) => (props.gridColumnGap ? props.gridColumnGap : style.gridColumnGap)};


    @media ${devices.downFromTablet} {
      display: flex;
      flex-direction: column;
    }

    ${(props) => props.formType === 'loanCalculator' && css`
    grid-template-columns: ${style.calculatorGridTemplate};
    `}
  }
`;

const GridHandler = styled.div`
  && {
    margin-right: ${style.gridMarginRight};
    grid-column-start: ${(props) => (props.start ? props.start : style.gridDefaultStart)};
    grid-column-end: ${(props) => (props.end ? props.end : style.gridDefaultEnd)};
    grid-row-start: ${(props) => (props.rowStart ? props.rowStart : '')};
    grid-row-end: ${(props) => (props.rowEnd ? props.rowEnd : '')};
    order: ${(props) => (props.desktopOrder ? props.desktopOrder : '')};

    @media ${devices.downFromMobile} {
      grid-column-start: 1;
      grid-column-end: 3;
      margin-right: 0;
    }

    @media ${devices.downFromTablet} {
      order: ${(props) => (props.mobileOrder ? props.mobileOrder : '')};
    }

    ${(props) => props.formType === 'loanCalculator' && css`
      @media ${devices.downFromDesktop}  {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: auto;
        grid-row-end: auto
      }
    `}
  }
`;

const ProgressBar = styled.progress`
  && {
    justify-content: center;
    width: 100%;
    min: ${(props) => props.min};
    max: ${(props) => props.max};
    value: ${(props) => props.value};
    height: ${style.progressBarHeight};
    margin: 0.5rem auto -5rem auto;
  }
`;

const SlideNav = styled.p`
  && {
    display: flex;
    align-items: baseline;
    padding: 1rem 0;
    justify-content: space-between;
  }
`;

const SlideHead = styled.div`
  && {
    display: grid;
    text-align: center;
    width: 100%;
    margin: 1.5rem;
    margin-top: 0;
    transform: translateY(-1rem);
  }
`;

const determineVariant = (type) => {
  switch (type) {
    case FormTypes.LOAN_CALCULATOR:
      return calculator;
    case FormTypes.LEAD_FORM:
      return lead;
    case FormTypes.MORTGAGE_LOAN:
      return mortgageFormData;
    default:
      return formData;
  }
};

const ApplicationForm = ({
  advisaSource,
  campaigns,
  disableCoApplicant,
  exampleLoan,
  form,
  initialValues,
  inputSource,
  intl,
  onSubmit,
  partnerForm,
  title,
  type,
  validate,
  submitSuccess,
  prefilledApplicationForm,
  ...rest
}) => {
  const slideComponents = {
    Teleporter,
    SimpleValueLabel,
    ExpandingGridList,
    FormBottom,
    Break,
    FormTextBlock,
    ValidatedForm,
    YearlyCost,
    MonthlyCost,
    InterestRateInput,
    TotalAmountInput,
    RepaymentTimeInput,
    ButtonGroup,
    EmploymentSinceInputYear,
    EmploymentSinceInputMonth,
    SubmitButton,
    ConsolidationLoanAmountInput,
    AcceptNewsLetter,
    AcceptAgreement,
    CreditCheckConsent,
    EmployerName,
    EmployerPhone,
    SummarySection,
    FormHeadline,
    TotalCost,
    SmallFormLabel,
    StoreValueDisplay,
    ReturningCustomerSlide,
    Disclaimer,
    FormQuestionsBox,
    TotalAmountDisplay,
    RepaymentTimeDisplay,
    UserAgreementDataPolicy,
    TestCurrentLoanSummary,
    TotalLoanWarning,
    AutoAccept,
    IdKollenLogin,
  };
  const formState = useSelector((state) => state.form.application);
  const dispatch = useDispatch();
  const setFieldVisibility = useCallback((...args) => {
    dispatch(setVisibleField(...args));
  }, [dispatch]);
  const changeField = (...args) => change('application', ...args);
  const changePreAppField = (...args) => change('prefilledApplication', ...args);
  const onStopSubmit = stopSubmit;
  const currentSlide = useSelector((state) => state.form.genericFormValues.currentSlide);
  const fieldErrors = useSelector((state) => (state.form.application.syncErrors
    ? state.form.application.syncErrors : {}));
  const mergeStatus = useSelector((state) => state.form.application.values?.merge);
  const hasCoApplicant = useSelector((state) => state.form.application.values?.hasCoApplicant);
  const visibleFields = useSelector((state) => state.form.genericFormValues);

  const values = { ...(formState.values || {}) };

  const showSubmitButton = ((!values.hasCoApplicant && currentSlide === 3)
    || (values.hasCoApplicant === 'undefined' && currentSlide === 3))
    || (values.hasCoApplicant === true && currentSlide === 4);

  const [errorsData, setErrorsData] = useState();

  const variant = determineVariant(type);

  const originalData = variant;

  const [data, setData] = useState(variant);

  const { lang } = useContext(LanguageContext);

  const [language, setLanguage] = useState('sv');

  const [showErrors, setShowErrors] = useState(false);

  const dataSlidesWithoutCoApplicant = data?.slides?.filter((e) => e.coApplicantSlide !== true);

  const topNodeRef = React.createRef();

  const [expandedForm, setExpandedForm] = useState();

  const applicationState = useSelector((state) => state.form.application);

  const [reapplyPage, setReapplyPage] = useState();

  const setCalculatedLabel = (condition) => {
    if (condition && applicationState.values) {
      const evaluateCondition = new Function('formState', condition);  //eslint-disable-line
      return evaluateCondition({ values: applicationState.values });
    }
    return false;
  };

  const isVisible = (field) => {
    const tools = {
      stripDelimiter: stripDelimiterFromCurrency,
    };
    if (field.props && field.props.visibleWhen && applicationState.values) {
      const evaluateCondition = new Function('formState', field.props.visibleWhen);  //eslint-disable-line
      return evaluateCondition({ values: applicationState.values, tools });
    }
    return true;
  };

  /* eslint-disable */
  useEffect(() => {
    if (region === 'FI' || brand === 'digifinans') {
      if (applicationState.values?.consolidationAmount !== applicationState.values?.totalAmount) { //eslint-disable-line
        dispatch(changeField('consolidationAmount', applicationState.values?.totalAmount)); //eslint-disable-line
      }
    }
  }, [applicationState.values?.totalAmount]);
  /* eslint-enable */

  useEffect(() => {
    const tools = {
      stripDelimiter: stripDelimiterFromCurrency,
    };
    for (let i = 0; i < data.slides[currentSlide].fields?.length; i += 1) {
      const field = data.slides[currentSlide].fields[i];
      if (field.props && field.props.visibleWhen && applicationState.values) {
        const evaluateCondition = new Function('formState', field.props.visibleWhen);  //eslint-disable-line
        const value = evaluateCondition({ values: applicationState.values, tools });
        setFieldVisibility(field.sectionName, field.props.name, value);
      }
    }
  }, [data, applicationState.values, currentSlide, setFieldVisibility]);

  const ABTest = (field) => {
    if (field.props && field.props.abTest === false) {
      return field.props.abTest;
    }
    return true;
  };

  const doesSlideHaveErrors = (currentFieldErrors) => {
    let output = false;

    for (let i = 0; i < data.slides[currentSlide]?.fields?.length; i += 1) {
      const item = data.slides[currentSlide].fields[i];
      const name = item.props.name || item.name;
      const visibleWhen = item.props.visibleWhen || null;
      let matchFoundInFieldErrors = false;

      // search for field in fieldErrors
      if (name) {
        if (item.sectionName) {
          let parent = currentFieldErrors[item.sectionName];

          // Dig into deep parent for currentLoans
          if (item.sectionName.includes('currentLoans')) {
            const sectionNameParts = item.sectionName.split('.');
            if (currentFieldErrors[sectionNameParts[0]]) {
              const parentPart1 = currentFieldErrors[sectionNameParts[0]];
              if (parentPart1[sectionNameParts[1]]) {
                const parentPart2 = parentPart1[sectionNameParts[1]];
                if (parentPart2[sectionNameParts[2]]) {
                  parent = parentPart2[sectionNameParts[2]];
                }
              }
            }
          }

          if (parent) {
            if (name in parent) {
              matchFoundInFieldErrors = true;
            }
          }
        } else if (name in currentFieldErrors) {
          matchFoundInFieldErrors = true;
        }
      }

      // invisible fields should not disable progress button
      if (matchFoundInFieldErrors) {
        if (visibleWhen && !isVisible(item)) {
          output = false;
        } else {
          return true;
        }
      }
    }
    if (region.region === 'FI' && values?.currentLoansMetaData?.totalRefinanceSum) {
      if (
        values.currentLoansMetaData.totalRefinanceSum
        > parseInt(formState.values.totalAmount, 10)
      ) {
        output = true;
      }
    }

    // all clear
    return output;
  };

  useEffect(() => {
    if (data !== variant) {
      setData(variant);
    }

    // lock body scrolling on fullview
    if (data.slides[currentSlide] && data.slides[currentSlide].fullview) {
      document.querySelector('html').setAttribute('style', 'overflow-y:hidden');
    } else {
      document.querySelector('html').setAttribute('style', 'overflow-y:unset');
    }
  }, [data, variant, currentSlide]);

  const sumUpCurrentLoansTransferAmount = (applicant) => {
    const refinancableLoans = values[applicant].currentLoanList.filter((obj) => obj?.transfer && obj?.amount); // eslint-disable-line

    const result = refinancableLoans.reduce((acc, obj) => acc + parseInt(obj.amount.replace(/\s/g, ''), 10), 0);
    if (values[applicant].currentLoans.refinanceSum !== result) {
      values[applicant].currentLoans.refinanceSum = result;
    }

    const appSum = values.applicant.currentLoans?.refinanceSum || 0;
    const coAppSum = values.coApplicant.currentLoans?.refinanceSum || 0;
    const totalRefinanceSum = appSum + coAppSum;

    values.applicant.currentLoans.totalRefinanceSum = totalRefinanceSum;
    values.applicant.currentLoans.diff = parseInt(values.totalAmount, 10) - totalRefinanceSum;
  };

  useEffect(() => {
    if (values.applicant?.currentLoanList) {
      sumUpCurrentLoansTransferAmount('applicant');
    }

    if (values.coApplicant?.currentLoanList) {
      sumUpCurrentLoansTransferAmount('coApplicant');
    }
  }, [values]); // eslint-disable-line

  useEffect(() => {
    setErrorsData(doesSlideHaveErrors(fieldErrors));
  }, [fieldErrors, values, mergeStatus, currentSlide]); // eslint-disable-line

  useEffect(() => {
    const dataClone = originalData.slides.length > 1
      ? JSON.parse(JSON.stringify(originalData)) : JSON.parse(JSON.stringify(data));
    // toggle coApplicant

    if (hasCoApplicant === true) {
      setData(originalData);
    } else {
      dataClone.slides = dataSlidesWithoutCoApplicant;
      setData(dataClone);
    }
  }, [hasCoApplicant, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.pathname === '/incoming-customer/') {
      setExpandedForm(true);
    }
    if (window.location.pathname === '/hae-lainaa/') {
      setExpandedForm(false);
    }
    if (variant === formData && brand === 'rahalaitos') {
      setExpandedForm(false);
    }
  }, [variant]);

  useEffect(() => {
    if (language !== lang) {
      setLanguage(lang);
    }
  }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

  let sourceFormType;

  if (values.merge) {
    sourceFormType = 'merge-loan';
    values.loanType = 'consolidation';
  } else {
    sourceFormType = 'new-loan';
    values.loanType = 'new';
  }

  const setLabelBasedOnKeyVals = (arr) => {
    let unchanged;

    for (let i = 0; i < arr.length; i += 1) {
      unchanged = arr[i].originalLabel;
      if (arr[i].type === 'calculation') {
        if (setCalculatedLabel(arr[i].calculateCondition)) {
          return arr[i].values[i].label;
        }
      }
      for (let j = 0; j < arr[i].values.length; j += 1) {
        const valuesSection = values[arr[i].values[j].sectionName];
        if (valuesSection && valuesSection[arr[i].key] === arr[i].values[j].name) {
          return arr[i].values[j].label;
        }
      }
    }
    return unchanged;
  };

  let isSelfServiceApplication;

  if (process.env.GATSBY_FRONTEND_BASE_URL === 'https://lana-pengarna.se') {
    // removeFalseyInObject-proof variables
    isSelfServiceApplication = 'false';
  }

  if (language === 'ar') {
    // removeFalseyInObject-proof variables
    isSelfServiceApplication = 'true';
  }

  const submit = (formValues) => {
    const application = {
      ...formValues,
      coApplicant: formValues.hasCoApplicant === true ? formValues.coApplicant : null,
      source:
        inputSource || `WEB, ${sourceFormType}-auto-submit, ${window.location.pathname}`,
    };
    const queryParams = {
      ...queryString.parse(window.location.search || ''),
      advisa_source: advisaSource,
      _ga: getCookie('_ga'),
      _fbp: getCookie('_fbp'),
      _fbc: getCookie('_fbc'),
      _scid: getCookie('_scid'),
      _ss: isSelfServiceApplication,
    };
    const meta = {
      query: queryString.stringify(removeFalseyInObject({ ...queryParams })),
      rawQuery: queryParams,
    };
    return onSubmit(
      originalData.general.mapper(application, visibleFields, type),
      meta,
    );
  };

  const startSubmit = (formValues) => submit({
    ...formValues,
  });

  const getDestinationPath = () => {
    if ((currentSlide - 1) < 0) {
      return '/';
    }
    return '';
  };

  const getDestinationPathNext = () => {
    if ((currentSlide + 1) < 0) {
      return '/';
    }
    return '';
  };

  const getDestinationIndex = () => {
    if (data.slides[currentSlide - 1]?.isReturningCustomerSlide) {
      return currentSlide - 2;
    }
    return currentSlide - 1;
  };

  const getDestinationIndexNext = () => {
    if (data.slides[currentSlide - 1]?.isReturningCustomerSlide) {
      return currentSlide + 2;
    }
    return currentSlide + 1;
  };

  const isOmalaina = brand === 'omalaina';
  const isDigiFinans = brand === 'digifinans';
  const isRahoitu = brand === 'rahoitu';
  const isFI = region.toUpperCase() === 'FI';

  useEffect(() => {
    if (isFI && window.location.pathname.includes('/reapply')) {
      setReapplyPage(true);
    }
  }, [isFI]);

  const calculatePadding = () => {
    let padding = style.loanCalculatorPadding;

    if (type === 'loanCalculator') {
      padding = '4rem 2rem 1rem';
    } else if (type === FormTypes.MORTGAGE_LOAN && region === 'NO' && currentSlide === 0) {
      padding = '6rem 2rem 1rem';
    }
    return padding;
  };

  return (
    <ApplicationFormBase
      defaultBreakpoints={defaultBreakpoints}
      form={form}
      formState={formState}
      initialValues={initialValues}
      validate={validate || validateApplicationForm}
      onSubmit={startSubmit}
      onSubmitFail={onSubmitFail}
      title={title}
      prefilledApplicationForm={prefilledApplicationForm}
      quickReapply={language === 'en' ? data?.links?.quickReapplyEng : data?.links?.quickReapply}
      userAgreement={language === 'en' ? data?.links?.userAgreementEng : data?.links?.userAgreement}
      dataPolicy={language === 'en' ? data?.links?.dataPolicyEng : data?.links?.dataPolicy}
      partnerForm={partnerForm}
      type={type}
      changePreAppField={changePreAppField}
      onStopSubmit={onStopSubmit}
      backgroundColor={data.backgroundColor}
      {...rest}
    >
      { reapplyPage && <IdKollenLogin />}
      <FormWrapper
        id="formWrapper"
        className="notranslate"
        translate="no"
        ref={topNodeRef}
        padding={calculatePadding()}
      >
        {data.slides?.map((slide, key) => (
          <div key={`${slide.title}-${key}`}>
            { (key === currentSlide || expandedForm) && (
              <>
                <div className={data.slides[currentSlide].fullview ? 'fullview-backdrop' : ''} />
                <div className={data.slides[currentSlide].fullview ? 'fullview' : ''}>
                  {(isDigiFinans || isRahoitu) && type !== 'lead' && type !== 'loanCalculator' && (
                  <ProgressBarStepper
                    step={currentSlide}
                    steps={data.slides}
                  />
                  )}
                  {data.slides[currentSlide].showBackButton && slide.title && !isOmalaina
                  && !isDigiFinans && !isRahoitu && (
                  <SlideNav>

                    {!isDigiFinans && (
                    <Teleporter
                      passedRef={topNodeRef}
                      expandedForm={expandedForm}
                      data={{
                        destinationIndex: getDestinationIndex(),
                        destinationPath: getDestinationPath(),
                        label: '←',
                      }}
                      setShowErrors={setShowErrors}
                      formType={type}
                    />
                    )}

                    <SlideHead>
                      { intl.formatMessage(slide.title)}

                      <ProgressBar
                        min={0}
                        max={values.hasCoApplicant
                          ? data.slides.length - 1 : dataSlidesWithoutCoApplicant.length - 1}
                        value={currentSlide}
                      />
                    </SlideHead>

                      { data.slides[currentSlide].showBackButton && !isDigiFinans && !isRahoitu && (
                        <Teleporter
                          passedRef={topNodeRef}
                          expandedForm={expandedForm}
                          data={{
                            destinationIndex: 0,
                            label: '✕',
                            closeButton: {
                              message: intl.formatMessage(messages.confirmationWarning),
                              yesButton: intl.formatMessage(messages.Yes),
                              noButton: intl.formatMessage(messages.No),
                            },
                          }}
                          formType={type}
                        />
                      )}
                  </SlideNav>
                  )}
                  <FieldGridWrapper
                    gridColumns={data.gridColumns}
                    gridColumnGap={data.gridColumnGap}
                    formType={type}
                  >
                    {slide.fields.map((item, itemKey) => {
                      let FormComponent;

                      if (slideComponents[item.component] === ValidatedForm) {
                        const test = slideComponents[item.component];
                        FormComponent = test[item.form];
                      } else {
                        FormComponent = slideComponents[item.component];
                      }

                      if (item.props.dynamicLabel) {
                        item.props.label = setLabelBasedOnKeyVals(item.props.dynamicLabel); // eslint-disable-line
                      }

                      if (isVisible(item) && ABTest(item)) {
                        if (item.component === 'ValidatedForm' || item.sectionName) {
                          return (

                            <GridHandler
                              start={item.props.layout?.start}
                              end={item.props.layout?.end}
                              rowStart={item.props.layout?.rowStart}
                              colEnd={item.props.layout?.colStart}
                              mobileOrder={item.props.layout?.mobileOrder}
                              formType={type}
                              isOmalaina={isOmalaina}
                              key={`${item.sectionName}-${itemKey}`}
                            >
                              <ValidatedForm.Section name={item.sectionName || ''} key={`${item.sectionName}-${itemKey}`}>
                                <FormComponent
                                  data={item.props}
                                  changeField={changeField}
                                  sectionName={item.sectionName}
                                  submitSuccess={submitSuccess}
                                  formType={type}
                                  expandedForm={expandedForm}
                                  disableButton={errorsData}
                                  setShowErrors={setShowErrors}
                                  showErrors={showErrors}
                                  passedRef={topNodeRef}
                                  descriptionText={
                                    intl.formatMessage(messages.emailSmartSuggestion)
                                  }
                                  invalidEmailWarningMessage={
                                    intl.formatMessage(messages.invalidEmailWarningMessage)
                                  }
                                  key={`${item.sectionName}-${itemKey}`}
                                />
                              </ValidatedForm.Section>
                            </GridHandler>
                          );
                        }
                        return (
                          <GridHandler
                            start={item.props.layout?.start}
                            end={item.props.layout?.end}
                            rowStart={item.props.layout?.rowStart}
                            colEnd={item.props.layout?.colStart}
                            mobileOrder={item.props.layout?.mobileOrder}
                            formType={type}
                            isOmalaina={isOmalaina}
                            key={`${item.sectionName}-${itemKey}`}
                          >
                            <FormComponent
                              data={item.props}
                              sectionName={item.sectionName}
                              changeField={changeField}
                              expandedForm={expandedForm}
                              submitSuccess={submitSuccess}
                              disableButton={errorsData}
                              passedRef={topNodeRef}
                              formType={type}
                              showErrors={showErrors}
                            />
                          </GridHandler>
                        );
                      }
                      return (
                        <GridHandler
                          start={0}
                          end={0}
                          key={itemKey}
                        >
                          {/* This keeps the map index on track */}
                        </GridHandler>
                      );
                    })}
                  </FieldGridWrapper>
                  {(isDigiFinans || isRahoitu) && type !== 'lead' && type !== 'loanCalculator' && (
                  <FieldGridWrapper>
                    {isDigiFinans && type !== 'lead' && (
                    <GridHandler
                      start={1}
                      end={4}
                      mobileOrder={3}
                    >
                      <FormQuestionsBox />
                    </GridHandler>
                    )}
                    <GridHandler
                      start={style.firstBottomButtonStart}
                      end={style.firstBottomButtonEnd}
                      mobileOrder={style.firstBottomButtonOrder}
                      desktopOrder={style.firstBottomButtonDesktopOrder}

                    >
                      <Teleporter
                        passedRef={topNodeRef}
                        expandedForm={expandedForm}
                        data={{
                          destinationIndex: getDestinationIndex(),
                          destinationPath: getDestinationPath(),
                          label: textResource.backButton,
                          ctaBtnStyle: true,
                          secondaryButton: true,
                        }}
                        formType={type}
                      />
                    </GridHandler>
                    {showSubmitButton ? (

                      <GridHandler
                        start={style.submitButtonStart}
                        end={style.submitButtonEnd}
                        desktopOrder={style.nextButtonOrder}
                        mobileOrder={-1}
                      >
                        <SubmitButton
                          disableButton={errorsData}
                          data={{
                            label: textResource.formSubmit,
                            ctaBtnStyle: true,
                            messages: {
                              uncaughtSubmitError: textResource.submitErrorUncaught,
                              generalSubmitError: textResource.submitErrorGeneral,
                            },
                          }}
                        />

                      </GridHandler>
                    ) : (
                      <GridHandler
                        start={style.secondBottomButtonStart}
                        end={style.secondBottomButtonEnd}
                        desktopOrder={style.nextButtonOrder}
                        mobileOrder={-1}
                      >
                        {' '}
                        <Teleporter
                          expandedForm={expandedForm}
                          passedRef={topNodeRef}
                          disableButton={errorsData}
                          data={{
                            destinationIndex: getDestinationIndexNext(),
                            destinationPath: getDestinationPathNext(),
                            label: textResource.nextSlide,
                            ctaBtnStyle: true,
                          }}
                          formType={type}
                        />
                      </GridHandler>
                    )}
                  </FieldGridWrapper>
                  )}
                </div>
              </>
            )}
          </div>
        ),
        )}
      </FormWrapper>
    </ApplicationFormBase>
  );
};

ApplicationForm.propTypes = {
  advisaSource: PropTypes.string,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      functionality: PropTypes.string,
      info: PropTypes.string,
    }),
  ),
  disableCoApplicant: PropTypes.bool,
  exampleLoan: PropTypes.shape(),
  form: PropTypes.string.isRequired,
  formState: PropTypes.shape(),
  initialValues: PropTypes.shape(),
  inputSource: PropTypes.string,
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  partnerForm: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.func,
  submitSuccess: PropTypes.bool,
  currentSlide: PropTypes.number,
  fieldErrors: PropTypes.shape(),
  prefilledApplicationForm: PropTypes.bool,
  mergeStatus: PropTypes.bool,
  changePreAppField: PropTypes.func,
};

ApplicationForm.defaultProps = {
  advisaSource: null,
  campaigns: undefined,
  disableCoApplicant: false,
  exampleLoan: null,
  formState: {},
  initialValues: {},
  inputSource: '',
  partnerForm: false,
  title: null,
  type: FormTypes.MERGE_LOAN,
  validate: null,
  changePreAppField: () => {},
  submitSuccess: false,
  currentSlide: 0,
  fieldErrors: {},
  prefilledApplicationForm: false,
  mergeStatus: null,
};

const ApplicationFormWithIntl = injectIntl(ApplicationForm);

export { ApplicationFormWithIntl as ApplicationForm };

export default ApplicationFormWithIntl;
